import CompanyHeroSection from "../Components/Company/CompanyHeroSection";
import CompanyPress from "../Components/Company/CompanyPress";

const Company = () => {
    return (
        <>
            <CompanyHeroSection/>
            <CompanyPress/>
        </>
    )
}
export default Company;