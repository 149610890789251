import GuidebooksHeroSection from "../Components/DigitalGuidebooks/GuidebooksHeroSection";
import GuidebooksSection1 from "../Components/DigitalGuidebooks/GuidebooksSection1";
import GuidebooksSection2 from "../Components/DigitalGuidebooks/GuidebooksSection2";

const Guidebooks = () => {
    return (
        <>
            <GuidebooksHeroSection/>
            <GuidebooksSection1/>
            <GuidebooksSection2/>
        </>
    )
}
export default Guidebooks;