import StreamlineHeroSection from "../Components/Streamline/StreamlineHeroSection";
import StreamlineSection1 from "../Components/Streamline/StreamlineSection1";
import StreamlineSection2 from "../Components/Streamline/StreamlineSection2";

const Streamline = () => {
    return (
        <>
            <StreamlineHeroSection/>
            <StreamlineSection1/>
            <StreamlineSection2/>
        </>
    )
}
export default Streamline;