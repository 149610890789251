import CheckOutsHeroSection from "../Components/Contactless/CheckOuts/CheckOutsHeroSection";
import CheckOutsSection1 from "../Components/Contactless/CheckOuts/CheckOutsSection1";
import CheckOutsSection2 from "../Components/Contactless/CheckOuts/CheckOutsSection2";

const CheckOuts = () => {
    return (
        <>
            <CheckOutsHeroSection/>
            <CheckOutsSection2/>
            <CheckOutsSection1/>
        </>
    )
}
export default CheckOuts;