import checkIn1 from "../../../assets/img/linkbase/PageImgs/checkIn1.png";

const CheckInSection5 = () => {
  return (
    <section className="content-block-wrapper section-padding bg-circle fix">
      <div className="col-xl-12 col-lg-12 pe-xl-4 col-12">
        <img src={checkIn1} alt="" />
      </div>
    </section>
  );
};

export default CheckInSection5;
